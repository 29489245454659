<template>
  <div class="commission-setting-dialog">
    <!-- start 项目提成规则对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span slot="title">
        <h3>{{ title }}</h3>
        <el-button type="primary" size="mini" plain @click="onShowCustom"
          >提成计算对象</el-button
        >
      </span>

      <div class="content-box">
        <el-form :model="editInfo" ref="form" label-width="140px" size="small">
          <el-form-item label="员工职位">
            <el-select
              v-model="editInfo.DutyNo"
              clearable
              @change="getCascadeLevel"
            >
              <el-option
                v-for="item in dutySelector"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="elementItem.Show == 1"
            v-for="(elementItem, elementIndex) in elements"
            :key="elementIndex"
            :label="elementItem.Code == '0007' ? '' : elementItem.Name"
          >
            <!-- <template v-if="elementItem.Code == '0001'">
              <el-select
                v-model="editInfo.DutyNo"
                clearable
                @change="getCascadeLevel"
              >
                <el-option
                  v-for="item in dutySelector"
                  :key="item.DutyNo"
                  :label="item.DutyName"
                  :value="item.DutyNo"
                >
                </el-option>
              </el-select>
            </template> -->

            <!-- 职位级别 -->
            <template v-if="elementItem.Code == '0001'">
              <el-select v-model="editInfo.LevelGuid" clearable @change="">
                <el-option
                  v-for="item in levelList"
                  :key="'LevelGuid' + item.LevelGuid"
                  :label="item.LevelName"
                  :value="item.LevelGuid"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 客户类型 -->
            <template v-else-if="elementItem.Code == '0002'">
              <el-select
                v-model="editInfo.ClientType"
                clearable
                @change="onChangeAccType"
              >
                <el-option
                  v-for="item in clientSelector"
                  :key="'ClientType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 卡类属性 -->
            <template v-else-if="elementItem.Code == '0003'">
              <el-select
                v-model="editInfo.NatureNo"
                clearable
                :disabled="editInfo.ClientType != 1"
                @change="getCascadePayment"
              >
                <el-option
                  v-for="item in natureSelector"
                  :key="'NatureNo' + item.NatureNo"
                  :label="item.NatureName"
                  :value="item.NatureNo"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 付款方式 -->
            <template v-else-if="elementItem.Code == '0004'">
              <el-select
                v-model="editInfo.PaymentGuid"
                clearable
                :disabled="!editInfo.ClientType"
                @change="getCascadeAcctype"
              >
                <el-option
                  v-for="item in paymentList"
                  :key="'PaymentGuid' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 账户类型 -->
            <template v-else-if="elementItem.Code == '0005'">
              <el-select
                v-model="editInfo.AccType"
                :disabled="
                  editInfo.ClientType == 1 && editInfo.PaymentGuid
                    ? false
                    : true
                "
                clearable
                @change=""
              >
                <el-option
                  v-for="item in acctypeList"
                  :key="'AccType' + item.Value"
                  :label="item.Name"
                  :value="Number(item.Value)"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 客户性别 -->
            <template v-else-if="elementItem.Code == '0006'">
              <el-select v-model="editInfo.ClientSex" clearable @change="">
                <el-option
                  v-for="item in sexSelector"
                  :key="'ClientSex' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </template>

            <!-- 价格范围 -->
            <template v-else-if="elementItem.Code == '0019'">
              <el-input
                class="price-input"
                v-model="editInfo.PriceFrom"
                :minlength="0"
                :maxlength="6"
              ></el-input>
              <span>~</span>
              <el-input
                class="price-input"
                v-model="editInfo.PriceTo"
                :minlength="0"
                :maxlength="6"
              ></el-input>
              <span class="tips">若两者输入0则表示不使用价格范围</span>
            </template>

            <!-- 累计业绩 -->
            <template v-else-if="elementItem.Code == '0007'">
              <el-checkbox
                v-model="editInfo.CumulativePerfor"
                :true-label="1"
                :false-label="0"
                label="累计业绩"
                @change=""
              ></el-checkbox>
            </template>

            <!-- 总业绩 -->
            <template v-else-if="elementItem.Code == '0008'">
              <el-select
                v-model="editInfo.PerforType"
                clearable
                @change="onChangeType($event, 'PerforValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'PerforType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.PerforValue"
                clearable
                :disabled="editInfo.PerforType == 0"
              >
                <template slot="append">{{
                  editInfo.PerforType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 材料费 -->
            <template v-else-if="elementItem.Code == '0009'">
              <el-select v-model="editInfo.CalcStuffType" clearable @change="">
                <el-option
                  v-for="item in stuffCalcSelector"
                  :key="'CalcStuffType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-select
                v-model="editInfo.StuffType"
                clearable
                @change="onChangeType($event, 'StuffValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'StuffType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                :value="editInfo.StuffValue"
                clearable
                :disabled="editInfo.StuffType == 0"
                @input="onChangeInput('StuffValue', editInfo.StuffType, $event)"
              >
                <template slot="append">{{
                  editInfo.StuffType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 附加材料费 -->
            <template v-else-if="elementItem.Code == '0018'">
              <el-select v-model="editInfo.CSType" clearable @change="">
                <el-option
                  v-for="item in stuffCalcAttchSelector"
                  :key="'CSType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-select
                v-model="editInfo.SType"
                clearable
                @change="onChangeType($event, 'SValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'SType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                :value="editInfo.SValue"
                clearable
                :disabled="editInfo.SType == 0"
                @input="onChangeInput('StuffValue', editInfo.StuffType, $event)"
              >
                <template slot="append">{{
                  editInfo.SType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 附加规则 -->
            <template v-else-if="elementItem.Code == '0010'">
              <el-select v-model="editInfo.RuleNo" clearable @change="">
                <el-option
                  v-for="item in royaRuleSelector"
                  :key="'RuleNo' + item.RuleNo"
                  :label="item.RuleName"
                  :value="item.RuleNo"
                >
                </el-option>
              </el-select>
              <!-- <el-select v-model="editInfo.RuleValueType" disabled clearable @change="">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-input v-model="editInfo.RuleValue" clearable @change="">
                <template slot="append">元</template>
              </el-input>
            </template>

            <!-- 基本业绩 -->
            <template v-else-if="elementItem.Code == '0011'">
              <el-select
                v-model="editInfo.BasicPerforType"
                clearable
                @change="onChangeType($event, 'BasicPerforValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'BasicPerforType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.BasicPerforValue"
                clearable
                :disabled="editInfo.BasicPerforType == 0"
              >
                <template slot="append">{{
                  editInfo.BasicPerforType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 基本提成 -->
            <template v-else-if="elementItem.Code == '0012'">
              <el-select
                v-model="editInfo.BasicRoyaType"
                clearable
                @change="onChangeType($event, 'BasicRoyaValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'BasicRoyaType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.BasicRoyaValue"
                clearable
                :disabled="editInfo.BasicRoyaType == 0"
              >
                <template slot="append">{{
                  editInfo.BasicRoyaType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 补底提成 -->
            <template v-else-if="elementItem.Code == '0013'">
              <span>补低</span>
              <el-input v-model="editInfo.BasicRoyaLowest" clearable @change="">
                <template slot="append">元</template>
              </el-input>
            </template>

            <!-- 指定业绩 -->
            <template v-else-if="elementItem.Code == '0014'">
              <el-select
                v-model="editInfo.AssignPerforType"
                clearable
                @change="onChangeType($event, 'AssignPerforValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'AssignPerforType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.AssignPerforValue"
                clearable
                :disabled="editInfo.AssignPerforType == 0"
              >
                <template slot="append">{{
                  editInfo.AssignPerforType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 指定提成 -->
            <template v-else-if="elementItem.Code == '0015'">
              <el-select
                v-model="editInfo.AssignRoyaType"
                clearable
                @change="onChangeType($event, 'AssignRoyaValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'AssignRoyaType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.AssignRoyaValue"
                clearable
                :disabled="editInfo.AssignRoyaType == 0"
              >
                <template slot="append">{{
                  editInfo.AssignRoyaType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 创单业绩 -->
            <template v-else-if="elementItem.Code == '0016'">
              <el-select
                v-model="editInfo.InventPerforType"
                clearable
                @change="onChangeType($event, 'InventPerforValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'InventPerforType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.InventPerforValue"
                clearable
                :disabled="editInfo.InventPerforType == 0"
              >
                <template slot="append">{{
                  editInfo.InventPerforType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>

            <!-- 创单提成 -->
            <template v-else-if="elementItem.Code == '0017'">
              <el-select
                v-model="editInfo.InventRoyaType"
                clearable
                @change="onChangeType($event, 'InventRoyaValue')"
              >
                <el-option
                  v-for="item in moneyOrRatioSelector"
                  :key="'InventRoyaType' + item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <el-input
                v-model="editInfo.InventRoyaValue"
                clearable
                :disabled="editInfo.InventRoyaType == 0"
              >
                <template slot="append">{{
                  editInfo.InventRoyaType == 2 ? "%" : "元"
                }}</template>
              </el-input>
            </template>
          </el-form-item>

          <el-form-item class="allow-minus">
            <el-checkbox
              v-model="editInfo.AllowMinusPerfor"
              :true-label="1"
              :false-label="0"
              label="业绩可负数"
              @change=""
            ></el-checkbox>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button @click="onCloseDialog">取消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="saveSingleSetting"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- end 项目提成规则对话框 -->

    <!-- start 提成计算对象对话框 -->
    <el-dialog
      title="提成计算对象"
      :visible.sync="showCustomDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      width="30%"
      @close="onCloseCustom"
    >
      <div class="custom-box">
        <el-checkbox
          v-for="(item, index) in copyElements"
          v-model="item.Show"
          :checked="item.Show ? true : false"
          :true-label="1"
          :false-label="0"
          :label="item.Name"
          :key="index"
        ></el-checkbox>
      </div>

      <span slot="footer">
        <el-button @click="onCloseCustom">取消</el-button>
        <el-button type="primary" @click="saveRoyaElements">确定</el-button>
      </span>
    </el-dialog>
    <!-- end 提成计算对象对话框 -->
  </div>
</template>

<script>
import Item from "@/api/item.js";

export default {
  props: {
    selectItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      title: "项目提成设置", // 标题
      type: "",
      settingItem: {}, // 项目信息

      editInfo: {}, // 规则信息
      clientSelector: [], // 客户类型
      dutySelector: [], // 职位类型
      elements: [], // 设置项目
      copyElements: [], // 设置项目拷贝数据
      moneyOrRatioSelector: [], // 按金额或比例
      natureSelector: [], // 卡类型
      royaRuleSelector: [], // 提成规则类型
      stuffCalcAttchSelector: [], // 材料
      stuffCalcSelector: [],
      sexSelector: [],

      levelList: [], // 职位级别列表
      paymentList: [], // 支付方式列表
      acctypeList: [], // 账户类型列表

      showDialog: false,
      showCustomDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 初始化保存设置
    async initSaveSetting() {
      try {
        let { data } = await Item.initSaveSetting({
          roya_guid: this.settingItem.RoyaGuid,
        });
        let {
          clientSelector,
          dutySelector,
          editInfo,
          elements,
          sexSelector,
          moneyOrRatioSelector,
          natureSelector,
          royaRuleSelector,
          stuffCalcAttchSelector,
          stuffCalcSelector,
        } = data;
        this.clientSelector = clientSelector;
        this.dutySelector = dutySelector;
        this.elements = elements;
        this.moneyOrRatioSelector = moneyOrRatioSelector;
        this.natureSelector = natureSelector;
        this.royaRuleSelector = royaRuleSelector;
        this.stuffCalcAttchSelector = stuffCalcAttchSelector;
        this.stuffCalcSelector = stuffCalcSelector;
        this.sexSelector = sexSelector;

        await this.initEditInfo(this.settingItem.RoyaGuid ? editInfo : "");
      } catch (err) {
        console.log(err);
      }
    },

    // 查询职位级别
    async getCascadeLevel() {
      try {
        let { data } = await Item.getCascadeLevel({
          duty_no: this.editInfo.DutyNo,
        });
        this.levelList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询付款方式
    async getCascadePayment() {
      try {
        let { data } = await Item.getCascadePayment({
          nature_no: this.editInfo.NatureNo,
          client_type: this.editInfo.ClientType,
        });
        this.paymentList = data;
        this.editInfo.PaymentGuid = "";
      } catch (err) {
        console.log(err);
      }
    },

    // 查询账户类型
    async getCascadeAcctype(event) {
      let arr = this.paymentList.filter((item) => item.Value == event);
      let type = arr.length ? arr[0].Type : "";
      try {
        let { data } = await Item.getCascadeAcctype({
          pay_type: type,
          nature_no: this.editInfo.NatureNo,
        });
        this.acctypeList = data;
        this.editInfo.PaymentType = type;
      } catch (err) {
        console.log(err);
      }
    },

    // 客户类型改变事件
    async onChangeAccType() {
      await this.getCascadePayment();
      await this.getCascadeAcctype();
    },

    // 限制输入框小数点事件
    onChangeInput(keyName, type = 0, event) {
      let str = "";
      if (type == 2 && (event.match(/\./g) || []).length === 1) {
        str = event.slice(event.lastIndexOf("."));
        str.length > 2 ? "" : (this.editInfo[keyName] = event);
      } else if (type == 1 && (event.match(/\./g) || []).length === 1) {
        str = event.slice(event.lastIndexOf("."));
        str.length > 3 ? "" : (this.editInfo[keyName] = event);
      } else if ((event.match(/\./g) || []).length < 2) {
        this.editInfo[keyName] = event;
      }
    },

    // 保存单个设置
    async saveSingleSetting() {
      let { editInfo, settingItem, type, selectItems } = this;
      this.submitDisabled = true;
      try {
        let submitData = await {
          AccType: Number(editInfo.AccType),
          AssignPerforType: Number(editInfo.AssignPerforType),
          AssignPerforValue: Number(editInfo.AssignPerforValue),
          AssignRoyaType: Number(editInfo.AssignRoyaType),
          AssignRoyaValue: Number(editInfo.AssignRoyaValue),
          AllowMinusPerfor: Number(editInfo.AllowMinusPerfor),
          BasicPerforType: Number(editInfo.BasicPerforType),
          BasicPerforValue: Number(editInfo.BasicPerforValue),
          BasicRoyaLowest: Number(editInfo.BasicRoyaLowest),
          BasicRoyaType: Number(editInfo.BasicRoyaType),
          BasicRoyaValue: Number(editInfo.BasicRoyaValue),
          CSType: Number(editInfo.CSType),
          CalcStuffType: Number(editInfo.CalcStuffType),
          ClientSex: Number(editInfo.ClientSex),
          ClientType: Number(editInfo.ClientType),
          CumulativePerfor: Number(editInfo.CumulativePerfor),
          DutyNo: editInfo.DutyNo,
          InventPerforType: Number(editInfo.InventPerforType),
          InventPerforValue: Number(editInfo.InventPerforValue),
          InventRoyaType: Number(editInfo.InventRoyaType),
          InventRoyaValue: Number(editInfo.InventRoyaValue),
          LevelGuid: editInfo.LevelGuid,
          NatureNo: editInfo.NatureNo,
          PaymentGuid: editInfo.PaymentGuid,
          PaymentSort: Number(editInfo.PaymentSort),
          PerforType: Number(editInfo.PerforType),
          PerforValue: Number(editInfo.PerforValue),
          RuleNo: editInfo.RuleNo,
          RuleValue: Number(editInfo.RuleValue),
          RuleValueType: Number(editInfo.RuleValueType),
          SType: Number(editInfo.SType),
          SValue: Number(editInfo.SValue),
          StoresGuid: editInfo.StoresGuid,
          StuffType: Number(editInfo.StuffType),
          StuffValue: Number(editInfo.StuffValue),
          PriceFrom: Number(editInfo.PriceFrom || 0),
          PriceTo: Number(editInfo.PriceTo || 0),
          ItemGuid: settingItem.ItemGuid || editInfo.ItemGuid,
          RoyaGuid: settingItem.RoyaGuid || editInfo.RoyaGuid,
        };
        let res;
        if (type == "single") {
          res = await Item.saveSingleSetting(submitData);
        } else {
          // console.log(submitData);
          let arr = await {
            Data: submitData,
            List: selectItems,
          };
          res = await Item.saveBatchSetting(arr);
        }
        let { data, errcode } = res;
        if (errcode == 0) {
          this.$message.success(`${type == "single" ? "保存成功" : data}`);
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 保存提成计算对象
    async saveRoyaElements() {
      try {
        let { errcode } = await Item.saveRoyaElements({
          List: this.copyElements,
        });
        if (errcode == 0) {
          this.$message.success("计算对象保存成功");
          this.copyElements = [];
          this.onCloseCustom();
          await this.$nextTick(async () => {
            await this.initSaveSetting();
            await this.getCascadeLevel();
            await this.onChangeAccType();
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化editInfo对象（增加时接口返回null，不赋初始值会报错）
    initEditInfo(params) {
      this.editInfo = params || {
        AccType: 0,
        AssignPerforType: 2,
        AssignPerforValue: 100,
        AssignRoyaType: 0,
        AssignRoyaValue: 0,
        AllowMinusPerfor: 0,
        BasicPerforType: 2,
        BasicPerforValue: 100,
        BasicRoyaLowest: 0,
        BasicRoyaType: 0,
        BasicRoyaValue: 0,
        CSType: 0,
        CalcStuffType: 0,
        ClientSex: 0,
        ClientType: 0,
        CumulativePerfor: 0,
        DutyNo: "",
        InventPerforType: 0,
        InventPerforValue: 0,
        InventRoyaType: 0,
        InventRoyaValue: 0,
        ItemGuid: "",
        LevelGuid: "",
        NatureNo: "",
        PaymentGuid: "",
        PaymentSort: 0,
        PerforType: 2,
        PerforValue: 100,
        RoyaGuid: "",
        RuleNo: "",
        RuleValue: 0,
        RuleValueType: 0,
        SType: 0,
        SValue: 0,
        StoresGuid: "",
        StuffType: 0,
        StuffValue: 0,
      };
    },

    // 金额比例下拉框改变事件
    onChangeType(type, keyName) {
      let value = 0;
      if (type == 2) {
        value = 100;
      }
      this.editInfo[keyName] = value;
    },

    // 打开对话框
    async onShowDialog({ items = {}, type = "single" }) {
      this.title = `${
        type == "batch" ? "批量" : items.RoyaGuid ? "修改" : "添加"
      }项目提成设置`;
      this.settingItem = items;
      this.type = type;
      await this.initSaveSetting();
      await this.getCascadeLevel();
      await this.onChangeAccType();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 打开提成计算对象
    onShowCustom() {
      this.copyElements = JSON.parse(JSON.stringify(this.elements));
      this.showCustomDialog = true;
    },

    // 关闭提成计算对象
    onCloseCustom() {
      this.showCustomDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.commission-setting-dialog {
  .el-dialog__header {
    > span {
      .flex-row;
      h3 {
        margin: 0;
        margin-right: 20px;
        padding: 0;
        font-size: 18px;
        font-weight: unset;
      }
    }
  }

  .content-box {
    max-height: 60vh;
    overflow-y: auto;
    .el-form {
      .el-form-item__content {
        .flex-row;

        .el-select,
        .el-input,
        span {
          margin-right: 10px;
        }

        .el-select {
          width: 180px;
        }

        .el-input {
          width: 150px;
        }

        .price-input {
          width: 80px;
        }

        .tips {
          font-size: 12px;
          color: #999;
        }
      }

      .allow-minus{
        .el-checkbox{
          // color: #999;
        }
      }
    }
  }
}

.custom-box {
  .flex-row;
  flex-wrap: wrap;
  .el-checkbox {
    width: calc(25% - 30px);
  }
}
</style>
