<template>
  <div class="item-roya">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <!-- 门店选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.class_guid"
          placeholder="请选择分类"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in classList"
            :key="item.ClassGuid"
            :label="item.ClassName"
            :value="item.ClassGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询文本框 -->
      <div class="df-col">
        <el-input v-model="submitData.word" size="mini" />
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearchData">
          查询
        </el-button>
      </div>

      <!-- 批量设置按钮 -->
      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          :disabled="selectionList.length == 0"
          @click="onShowRuleDialog({}, -1, 'batch')"
        >
          批量设置
        </el-button>
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 项目提成表 -->
    <el-table
      :data="settingList"
      border
      stripe
      :max-height="tableMaxHeight"
      @select="onChangeSelection"
      @select-all="onChangeAllSelection"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="ItemNo"
        label="项目编号"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ItemName"
        label="项目名称"
        width="200"
        align="center"
      >
      <template slot-scope="scope">
        <div class="item">
          <span>{{ scope.row.ItemName }}</span>
          <span>{{ scope.row.Price }}元</span>
        </div>
      </template>
    </el-table-column>
      <el-table-column
        prop="ClassName"
        label="分类名称"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column label="提成规则" align="center">
        <template slot-scope="scope">
          <div class="rule-list">
            <div
              class="rule-item"
              v-for="(item, index) in scope.row.RuleTextList"
              :key="index"
            >
              <span>{{ item.DisplayText }}</span>
              <div class="span-box">
                <span @click="onShowRuleDialog(scope.row, index)">[修改]</span>
                <span @click="deleteSetting(item)">[删除]</span>
              </div>
            </div>

            <div class="span-box">
              <span @click="onShowRuleDialog(scope.row)">[增加]</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目提成表 -->

    <!-- start 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="submitData.page_index"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="submitData.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
    >
    </el-pagination>
    <!-- end 分页器 -->

    <CommissionSettingDialog
      ref="CommissionSettingDialog"
      :selectItems="selectItems"
      @success="onSaveSuccess"
    ></CommissionSettingDialog>
  </div>
</template>

<script>
import Item from "@/api/item.js";
import Service from "@/api/service.js";

import CommissionSettingDialog from "./components/commission-setting-dialog.vue";

export default {
  components: { CommissionSettingDialog },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      submitData: {
        class_guid: "", // 分类guid
        word: "", // 关键字
        page_index: 1, // 页码
        page_size: 200, // 页数
      }, // 提交数据
      totalRecord: 0, // 总数
      settingList: [], // 设置数据列表
      classList: [], // 分类列表
      selectionList: [],
      selectItems: [],
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.getItemclass();
    this.getSettingList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 52);
    },

    // 获取项目分类列表
    async getItemclass() {
      try {
        let { data } = await Service.getItemclass();
        this.classList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取设置列表
    async getSettingList() {
      try {
        let { data } = await Item.getSettingList(this.submitData);
        this.settingList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 批量保存设置
    async saveBatchSetting() {
      let submitData = {
        Data: [],
        List: [{}],
      };
      try {
        let { errcode } = await Item.saveBatchSetting(submitData);
      } catch (err) {
        console.log(err);
      }
    },

    // 删除设置
    async deleteSetting(event) {
      try {
        let { errcode } = await Item.deleteSetting({
          roya_guid: event.RoyaGuid,
        });
        if (errcode == 0) {
          this.$message.success("删除成功");
          this.getSettingList();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 搜索按钮点击事件
    onSearchData() {
      this.submitData.page_index = 1;
      this.getSettingList();
    },

    // 打开项目提成设置对话框
    onShowRuleDialog(event = {}, index = -1, type = "single") {
      let submitData = {};
      event.RuleTextList && index > -1
        ? (submitData = { ...event.RuleTextList[index] })
        : "";
      submitData = {
        ItemGuid: event.ItemGuid,
        RoyaGuid: submitData.RoyaGuid || "",
        // ...submitData,
      };
      // console.log(submitData);
      this.$refs.CommissionSettingDialog.onShowDialog({
        items: submitData,
        type,
      });
    },

    // 表格多选项改变事件
    async onChangeSelection(event, row) {
      // console.log(event, row);
      if (
        event.length > 0 &&
        event[event.length - 1].ItemGuid == row.ItemGuid
      ) {
        let arr = [];
        // if (row.RuleTextList.length) {
        //   await row.RuleTextList.forEach((item, index) => {
        //     arr[index] = {
        //       ItemGuid: row.ItemGuid,
        //       ItemName: row.ItemName,
        //       RoyaGuid: item.RoyaGuid,
        //     };
        //   });
        // } else {
        await arr.push({
          ItemGuid: row.ItemGuid,
          ItemName: row.ItemName,
          // RoyaGuid: "",
        });
        // }

        this.selectItems = await [...this.selectItems, ...arr];
      } else {
        await this.selectItems.find(async (item, index) => {
          if (item.ItemGuid == row.ItemGuid) {
            await this.selectItems.splice(index, 1);
            return;
          }
        });
      }

      await this.$nextTick(() => {
        this.selectionList = event;
      });
    },

    // 表格多选项全选改变事件
    async onChangeAllSelection(event) {
      let arr = [];

      this.selectionList = event;

      if (!event.length) {
        return (this.selectItems = []);
      }

      await event.forEach(async (items, itemIndex) => {
        // let object = {
        //   ItemGuid: items.ItemGuid,
        //   ItemName: items.ItemName,
        //   RoyaGuid: "",
        // };

        // if (items.RuleTextList.length) {
        //   await items.RuleTextList.forEach(async (rules, ruleIndex) => {
        //     object.RoyaGuid = await rules.RoyaGuid;
        //     arr[itemIndex] = await object;
        //   });
        // } else {
        //   arr[itemIndex] = await object;
        // }

        await arr.push({
          ItemGuid: items.ItemGuid,
          ItemName: items.ItemName,
        });
      });

      await this.$nextTick(async () => {
        // await console.log(arr);
        this.selectItems = await arr;
      });
    }, 

    // 保存成功返回事件
    onSaveSuccess() {
      this.selectionList = [];
      this.selectItems = [];
      this.getSettingList();
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      console.log(event);
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getSettingList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getSettingList();
    },
  },
};
</script>

<style lang="less" scoped>
.item-roya {
  padding-top: 20px;
  box-sizing: border-box;

  .el-table {
    .rule-list {
      .flex-col;
      .rule-item {
        .flex-row;
      }
    }

    .span-box {
      color: #409eff;
      span {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .item{
      .flex-col;
    }
  }

  .el-pagination {
    float: right;
    margin-top: 20px;
  }
}
</style>
